// 2018 Creative refresh
$gluxre_update: true;

// SD SPP Product
$sd_spp_sticky: true;
$sd-spp-add-to-bag: true;

// Loyalty Redesign variable
$loyalty-redesign: true !default;

// EsteeLauder VN Fonts update
$akzidenz: 'AkzidenzGrotesk W1G';
$akzidenzregular: $akzidenz;
$akzidenzgroteskpro-regular: $akzidenz;
$font-akzidenzgrotesk: $akzidenz;
$optimalight: 'Optima VN';
$optimaregular: 'Optima Display Roman';
$arsenal: 'Arsenal';
$optima-reg: $optimaregular;
$optimaregular: $optimaregular;
$optimaUltralight: $optimaregular;
$optima-book: $optimaregular;
$optimabook: $optimaregular;
$optimatext: $optimaregular;
$OptimaDisplayBook: $optimaregular;
